import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Routes } from '../../../routes';
import { validateUser, setUserSignUp, validateCognito, setUserValid } from '../../redux/auth';
import { Strings } from '../../shared/constants/Strings';
import { getValueDocument, isEmpty, maxLengthCheck } from '../../../util/Util';
import { STORAGE_TOKEN_APP, typeDocument } from '../../../util/Constant';
import AuthService from '../../shared/services/AuthService';
import { GAProvider } from '../../components/PSAnalytics';
import ValidationIframe from '../SignKonfidi';
import { PSCaptcha } from '../../components/molecules';
import PSModalFAD from '../../components/organisms/PSModalFAD';
import PSLoginContainer from '../../components/PSLoginContainer';
import PSHeaderLogin from '../../components/PSHeaderLogin';
import PSTextInput from '../../components/PSTextInput';
import PSButton from '../../components/PSButton';
import PSBox from '../../components/PSBox';
import PSLabel from '../../components/PSLabel';
import PSSelect from '../../components/PSSelect';
import PSError from '../../components/PSError';
import PSMessageResponse from '../../components/PSMessageResponse';
import PSLoading from '../../components/PSLoading';

import './index.scss';

const MAXIMUN_ATTEMPTS=3

export const getPlaceholderInput = (type) => {
  switch (type) {
    case 'L':
      return 'DNI';
    case 'E':
      return 'Carnet de Extranjería';
    case 'R':
      return 'Número de documento';
    case 'G':
      return 'Pasaporte';
    default:
      return 'DNI';
  }
};

const SignUpForm = ({
  formik,
  onClose,
  loading,
  loadingToken,
  captcha,
  onChangeCaptcha,
  validCaptcha,
  hasKeyCaptcha,
}) => (
  <PSLoginContainer
    className={`user-container  ${loadingToken ? 'container-loading' : ''}`}
    onClose={onClose}
    loading={loading}
  >
    {loadingToken ? (
      <PSLoading />
    ) : (
      <>
        <PSHeaderLogin
          className="user-header-login"
          title="Registro"
          icon={formik.values.documentType === 'R' ? 'business' : 'user'}
        >
          <PSLabel className="user-header-title">
            {formik.values.documentType === 'R'
              ? 'Por favor, ingrese los datos de la empresa:'
              : 'Por favor, ingrese los siguientes datos:'}
          </PSLabel>
        </PSHeaderLogin>
        <form className="baseForm" onSubmit={formik.handleSubmit} noValidate>
          <PSBox className="user-box-login">
            <PSSelect
              {...formik.getFieldProps('documentType')}
              options={[{ value: '', label: 'Seleccione documento' }, ...typeDocument]}
              selectedValue={formik.values.documentType}
              className={
                formik.touched.documentType && formik.errors.documentType ? 'with-error' : ''
              }
            />

            <PSError>{formik.touched.documentType && formik.errors.documentType}</PSError>
            <PSTextInput
              type="text"
              maxLength={formik.values.documentType === 'L' ? 8 : 11}
              onKeyPress={(e) => (formik.values.documentType !== 'G' ? maxLengthCheck(e) : null)}
              placeholder={getPlaceholderInput(formik.values.documentType)}
              {...formik.getFieldProps('documentId')}
              className={formik.touched.documentId && formik.errors.documentId ? 'with-error' : ''}
              onChange={(e) => {
                e.target.value = e.target.value?.toUpperCase();
                formik.getFieldProps('documentId').onChange(e);
              }}
            />
            <PSError>{formik.touched.documentId && formik.errors.documentId}</PSError>
          </PSBox>
          {hasKeyCaptcha && <PSCaptcha ref={captcha} onChange={onChangeCaptcha} />}
          <PSBox className="user-footer">
            <PSButton
              className="btn-submit"
              type="submit"
              loading={loading}
              disabled={hasKeyCaptcha && !validCaptcha}
            >
              Validar
            </PSButton>
          </PSBox>
        </form>
      </>
    )}
  </PSLoginContainer>
);

const ErrorMessage = ({ userStatus, onClose }) => {
  const getErrorMessage = () => {
    if (userStatus === 'NO_DATA_USER') {
      return Strings.USER_INCOMPLETE;
    }

    if (userStatus === 'DATA_COMPLETED') {
      return Strings.USER_COMPLETED;
    }

    if (userStatus === 'USER_INVALID_POLICIES') {
      return Strings.USER_INVALID_POLICIES;
    }

    if (userStatus === 'ERROR_TIME') {
      return Strings.USER_ERROR_TIME;
    }

    if (userStatus === 'USER_INVALID_ACCESS') {
      return Strings.USER_INVALID_ACCESS;
    }

    return Strings.USER_NOT_VALID;
  };

  return (
    <PSMessageResponse
      icon={userStatus === 'USER_INVALID_ACCESS' ? 'problemsServer' : 'face'}
      onClose={onClose}
    >
      {getErrorMessage()}
    </PSMessageResponse>
  );
};

export default function SignUp({ closeModal }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isUserValid, loading, cognitoValid, signUp } = useSelector((state) => state.auth);
  const captcha = useRef(null);
  const [showError, setError] = useState(false);
  const [loadingToken, setLoadingToken] = useState(true);
  const [validCaptcha, setValidCaptcha] = useState(null);
  const hasKeyCaptcha = process.env.REACT_APP_RECAPTCHA_KEY ? true : false;
  const [showIframe, setShowIframe] = useState(false);
  const ga = React.useContext(GAProvider);
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [showAlertAF ,setShowAlertAF] = useState(false)

  const formik = useFormik({
    initialValues: {
      documentId: '',
      // date: '',
      documentType: '',
    },
    validationSchema: Yup.object().shape({
      documentId: Yup.string()
        .min(8, 'Necesita mínimo 8 caracteres')
        .required('Documento es requerido')
        .when('documentType', {
          is: 'L',
          then: Yup.string()
            .max(8, 'El documento solo tiene 8 caracteres')
            .required('DNI es requerido'),
        })
        .when('documentType', {
          is: 'R',
          then: Yup.string()
            .min(11, 'El documento debe tener al menos 11 dígitos')
            .required('RUC es requerido'),
        }),
      documentType: Yup.string().required('Seleccione el tipo de documento'),
    }),

    onSubmit(form) {
      dispatch(setUserSignUp(form));
      dispatch(validateCognito(form.documentId, form.documentType,validCaptcha));
    },
  });

  const onClose = () => {
    closeModal();
  };

  const closeFAD = () => {
    onClose()
  }

  useEffect(() => {
    if (
      [
        'INVALID_USER',
        'ERROR_USER',
        'NO_DATA_USER',
        'USER_INVALID_POLICIES',
        'ERROR_TIME',
        'USER_INVALID_ACCESS',
      ].includes(isUserValid)
    ) {
      setError(true);
    }

    if (isUserValid === 'VALID_USER') {
      const documentType = formik.values.documentType.toString();
      const typeUser = documentType === 'R' ? 'EMPRESA-' : 'PERSONA-';
      const labelGA = 'El usuario inició el flujo de enrollment';

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${typeUser}Enrollment`,
        action: 'click',
        label: labelGA,
        value: 1,
      });

      if (formik.values.documentType === 'R') {
        history.push(Routes.SIGN_UP_REGISTER_COMPANY);
      }
      else if (formik.values.documentType === 'E') {
            history.push({
                pathname: Routes.SIGN_UP_VALIDATION,
            });  
      } 
      else if (!isEmpty(formik.values.documentType)) {
        // TODO: Create new error messages
        //let errorCount = getFadError(formik.values.documentId)
        //if (errorCount >= MAXIMUN_ATTEMPTS) setShowAlertAF(true);
        // setShowIframe(true);
        // setDocumentType(getValueDocument(formik.values.documentType))
        // setDocumentNumber(formik.values.documentId)
        history.push({
            pathname: Routes.SIGN_UP_VALIDATION,
        });
      }
    }
  }, [isUserValid]);

  const onChangeCaptcha = () => {
    const valueCaptcha = captcha.current.getValue();
    if (valueCaptcha) setValidCaptcha(valueCaptcha);
  };

  useEffect(() => {
    if (cognitoValid === 'INVALID_USER') {
      dispatch(validateUser(signUp));
    }

    if (['VALID_USER', 'BLOCKED_USER'].includes(cognitoValid)) {
      dispatch(setUserValid('DATA_COMPLETED'));
      setError(true);
    }
  }, [cognitoValid]);

  const loadToken = async () => {
    try {
      const response = await AuthService.getTokenApp();
      if (
        response.authenticationResponse &&
        response.authenticationResponse.authenticationState === 200
      ) {
        const token = response.authenticationResponse.authenticationData.securityToken;
        localStorage.setItem(STORAGE_TOKEN_APP, token);
      }
      setLoadingToken(false);
    } catch (error) {
      setLoadingToken(false);
      console.log(error);
    }
  };

  useEffect(() => {
    loadToken();
  }, []);

  return (
    <div className="user-register-container">
     {!showError && (
        <>
          {showIframe ? (
            showAlertAF ? (
                <PSModalFAD 
                  isVisible={showAlertAF} 
                  handlerIcon={closeFAD}
                  title="Registro facial bloqueado"
                  />
            ) : <ValidationIframe
              documentType={documentType}
              documentNumber={documentNumber}
              validationPercentage={80} 
              showIframe={showIframe}
              onClose={onClose}
            />
          ) : <SignUpForm
            formik={formik}
            userStatus={isUserValid}
            onClose={onClose}
            loading={loading}
            loadingToken={loadingToken}
            captcha={captcha}
            onChangeCaptcha={onChangeCaptcha}
            validCaptcha={validCaptcha}
            hasKeyCaptcha={hasKeyCaptcha}
          />}
        </>
      )}
      {showError && <ErrorMessage userStatus={isUserValid} onClose={closeModal} />}
    
    </div>
  );
}
